import { AddUpdateCommand } from './add-update-command';
export class AddUpdateContrattoCommand extends AddUpdateCommand {
  public idCliente: number;
  public idFornitore: number;
  public codice: string;
  public dataContratto: Date;
  public dataInizioAttivita: Date;
  public dataFineAttivita: Date;
  public tipologia: boolean;
}
