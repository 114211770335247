import { PxltkComboName } from './enum/pxltk-combo-name';
import { PxltkFormType } from './enum/pxltk-form-type';

export class PxltkFormModel {
  public type: PxltkFormType;
  public name: string;
  public placeholder: string;
  public label: string;
  public defaultValue: any;
  public required: boolean;
  public comboName: PxltkComboName;
  public disable: boolean;

  constructor(
    defaultValue: any,
    label: string,
    name: string,
    placeholder: string,
    required: boolean,
    type: PxltkFormType,
    comboName?: PxltkComboName,
    disable?: boolean
  ) {
    this.defaultValue = defaultValue;
    this.label = label;
    this.name = name;
    this.placeholder = placeholder;
    this.required = required;
    this.type = type;
    this.comboName = comboName;
    this.disable = disable;
  }
}
