import { ListCommand } from './list-command';
export class SearchFatturaCommand extends ListCommand {
  public numeroFattura: string;
  public dataFattura: Date;
  public dataScadenza: Date;
  public dataPagamento: Date;
  public dataAnnoFattura: Date;
  public dataAnnoScadenza: Date;
  public dataAnnoPagamento: Date;
  public descrizione: string;
  public imponibile: number;
  public imposta: number;
  public valoreImposta: number;
  public totaleFattura: number;
  public idCliente: number;
  public causale: string;
  public tipologia: boolean;
  public idFornitore: number;
  public annoRiferimento: Date;
  public fattureScaduteDaPagare: boolean;
}
