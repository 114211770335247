import { AddUpdateCommand } from './add-update-command';
export class AddUpdateFatturaCommand extends AddUpdateCommand {
  public numeroFattura: string;
  public dataFattura: Date;
  public dataScadenza: Date;
  public dataPagamento: Date;
  public descrizione: string;
  public imponibile: number;
  public imposta: number;
  public valoreImposta: number;
  public totaleFattura: number;
  public idCliente: number;
  public causale: string;
  public tipologia: boolean;
  public idFornitore: number;
  public idTipoPagamento: number;
  public idTipoFattura: number;
  public pagataInAcconti: boolean;
  public annoRiferimento: Date;
  public imponibileMaggiore: boolean;
}
