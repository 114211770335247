import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSmartModalComponent, NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppConstants } from 'src/app/shared/app-constants';
import { SharedCallService } from 'src/app/shared/shared-services/shared-call.service';
import { EncryptionService } from '../../shared/shared-services/encryption.service';
import { SharedService } from '../../shared/shared-services/shared.service';
import { AddUpdateCommand } from '../command/add-update-command';
import { PxltkComboName } from '../enum/pxltk-combo-name';
import { PxltkFormType } from '../enum/pxltk-form-type';
import { PxltkFormModel } from '../pxltk-form-model';
import { AbstractBaseComponent } from './abstract-base-component';

export abstract class AbstractFormComponent
  extends AbstractBaseComponent
  implements OnDestroy, OnInit, AfterViewInit
{
  protected idSocieta: PxltkFormModel;
  formItems: PxltkFormModel[];
  submitted = false;
  id: number;
  title = '';
  urlToCall: string;
  public showBack: boolean;

  constructor(
    public sharedCallService: SharedCallService,
    public router: Router,
    public ngxSmartModalService: NgxSmartModalService,
    public spinner: NgxSpinnerService,
    public route: ActivatedRoute,
    public encryptionService: EncryptionService,
    public sharedService: SharedService
  ) {
    super(sharedCallService, encryptionService);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.initForm();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  abstract setResultDataToForm(result: any);
  abstract pushFormItems();
  abstract createFormItems();

  initForm() {
    let societaDefaultValue = '';
    if (!!sessionStorage.getItem('currentIdSocieta')) {
      societaDefaultValue =
        '' + JSON.parse(sessionStorage.getItem('currentIdSocieta'));
    }
    // tslint:disable-next-line: max-line-length
    this.idSocieta = new PxltkFormModel(
      societaDefaultValue,
      AppConstants.LABEL_SOCIETA,
      AppConstants.ID_SOCIETA_CODE,
      AppConstants.LABEL_SOCIETA,
      true,
      PxltkFormType.COMBO,
      PxltkComboName.SOCIETA,
      true
    );
    this.createFormItems();
    this.route.data.subscribe((data) => {
      this.title = data.title;
      this.urlToCall = data.urlToCall;
      this.showBack = data.showBack;
      if (data.isModifica) {
        this.route.queryParams.subscribe((params) => {
          this.spinner.show();
          this.sharedCallService.get(params.id, data.getUrl).subscribe(
            (result) => {
              result = this.encryptionService.decrypt(result);
              this.id = result.id;
              this.setResultDataToForm(result);
              this.pushFormItems();
              this.spinner.hide();
            },
            (error) => {
              console.log('Errore ' + data.getUrl);
              this.spinner.hide();
            }
          );
        });
      } else {
        this.pushFormItems();
      }
    });
  }

  protected saveOrUpdate(
    command: AddUpdateCommand,
    pageToRedirect?: string,
    refreshLocation?: boolean
  ): void {
    command.id = this.id;
    command.idSocieta = this.currentIdSocieta;
    command.currentIdDipendente = this.currentUser.dipendente.id;
    this.sharedCallService.createOrUpdate(command, this.urlToCall).subscribe(
      (result) => {
        result = this.encryptionService.decrypt(result);
        this.spinner.hide();
        if (result) {
          this.sharedService.showOkAlert();
          this.modalSubscribe = this.ngxSmartModalService
            .getModal('okModal')
            .onClose.subscribe((modal: NgxSmartModalComponent) => {
              refreshLocation
                ? window.location.reload()
                : this.router.navigate([pageToRedirect]);
            });
        } else {
          this.sharedService.showKoAlert();
        }
      },
      (error) => {
        this.spinner.hide();
        this.sharedService.showKoAlert();
      }
    );
  }
}
