import { AddUpdateCommand } from './add-update-command';
export class AddUpdateEntrateUsciteCommand extends AddUpdateCommand {
  public idTipoPagamento: number;
  public importo: number;
  public tipologia: boolean;
  public causale: string;
  public dataOperazione: Date;
  public isAcconto: boolean;
  public idFatture: string;
  public annoRiferimento: Date;
}
